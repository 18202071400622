import { Form, redirect, NavLink } from "react-router-dom";

const Report = () => {
  return (
    <section className="hero">
        <div className="inner-container">
            <div className="section">
                <h1>Have you been compromised?</h1>
                <p>Enter your email address to get your report...</p>
                <Form method="post" action="/">
                    <input type="email" name="email" placeholder="example@example.com" />
                    <button type="submit" class="className">Get Report</button>
                </Form>
            </div>
        </div>
    </section>
  )
}

export default Report
