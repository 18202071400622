import { useLoaderData, useParams } from 'react-router-dom'
import CountUp from 'react-countup';


export default function Reportdetails() {
    const { email } = useParams()
    let resdata = useLoaderData()
    resdata = JSON.parse(resdata)

    let totaluniquedetails = [];
    let pwdarray = [];
    let uniquepwds = [];
    let totalbreaches = resdata.total;
    
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    
    today = dd + '/' + mm + '/' + yyyy;

    if(totalbreaches === "noemail"){
        return (
            <div className='App'>
            
                    <div className='inner-container report-content'>
                        <div className='instances'>
                            <h2>No email address</h2>
                        </div>
                    </div>
                </div>
        )
    } else {
        if(totalbreaches !== 0){
            resdata.entries.forEach(element => {
    
                if(element.username !== '' && !totaluniquedetails.includes(element.username)){
                    totaluniquedetails.push(element.username);
                }
                if(element.password !== '' && !totaluniquedetails.includes(element.password)){
                    totaluniquedetails.push(element.password);
                    pwdarray.push(element.password);
                    uniquepwds.push(element.password);
                }
                if(element.hashed_password !== '' && !totaluniquedetails.includes(element.hashed_password)){
                    totaluniquedetails.push(element.hashed_password);
                }
                if(element.name !== '' && !totaluniquedetails.includes(element.name)){
                    totaluniquedetails.push(element.name);
                }
                if(element.address !== '' && !totaluniquedetails.includes(element.address)){
                    totaluniquedetails.push(element.address);
                }
                if(element.ip_address !== '' && !totaluniquedetails.includes(element.ip_address)){
                    totaluniquedetails.push(element.ip_address);
                }
                if(element.phone !== '' && !totaluniquedetails.includes(element.phone)){
                    totaluniquedetails.push(element.phone);
                }
        
            });
    
            
            let totalpasswordval = countUnique(pwdarray);
            let totaldetailsval = countUnique(totaluniquedetails);
        
        
            
        
          return (
            <div className='App'>
                <div className='inner-container'>
                    <div className='report-header-head'>
                        <p>Email Address: {email}</p>
                        <p>Date: {today}</p>
                    </div>
                    <div className='report-header'>
                        <div className='report-counter'>
                            <h3><CountUp end={totalbreaches} duration={1} /></h3>
                            <p>Breaches</p>
                        </div>
                        <div className='report-counter'>
                            <h3><CountUp end={totalpasswordval} duration={1} /></h3>
                            <p>Cleartext Passwords</p>
                        </div>
                        <div className='report-counter'>
                            <h3><CountUp end={totaldetailsval} duration={1} /></h3>
                            <p>Total Unique Details</p>
                        </div>
                    </div>
                </div>
        
                <div className='inner-container report-content'>
                    <div className='instances'>
                        <h2>Breach Instance Details</h2>
                        {resdata.entries.map(res => (
                            <div className='instance' key={res.id}>
                                <div className='instance-head'>
                                    <p>ID: {res.id}</p>
                                    <p>Breach: {res.database_name}</p>
                                </div>
                                <div className='row'>
                                    <div className='item'><p>IP Address:</p></div>
                                    <div className='item'><p>{res.ip_address}</p></div>
                                </div>
                                <div className='row'>
                                    <div className='item'><p>Username:</p></div>
                                    <div className='item'><p>{res.username}</p></div>
                                </div>
                                <div className='row'>
                                    <div className='item'><p>Password:</p></div>
                                    <div className='item'><p>{res.password}</p></div>
                                </div>
                                <div className='row'>
                                    <div className='item'><p>Hashed Password:</p></div>
                                    <div className='item'><p>{res.hashed_password}</p></div>
                                </div>
                                <div className='row'>
                                    <div className='item'><p>Name:</p></div>
                                    <div className='item'><p>{res.name}</p></div>
                                </div>
                                <div className='row'>
                                    <div className='item'><p>Address:</p></div>
                                    <div className='item'><p>{res.address}</p></div>
                                </div>
                                <div className='row'>
                                    <div className='item'><p>Phone Number:</p></div>
                                    <div className='item'><p>{res.phone}</p></div>
                                </div>
                            </div>
                        ))}
                    </div>
        
                    <div className='info'>
                        <a href="#" className='download-btn' onClick={() => getpdf(email,resdata,totaldetailsval,totalpasswordval,today)}>Download PDF Report</a>
                        <div className='instance'>
                            <div className='instance-head'>
                                <p>Unique Passwords</p>
                            </div>
                            {uniquepwds.map(pwd => (
                                <div className='row'>
                                    <div className='item'><p>{pwd}</p></div>
                                </div>
                            ))}
                        </div>
                    </div>
        
                </div>
            </div>
          )
        } else {
            // No email address provided
            return (
                <div className='App'>
                    <div className='inner-container'>
                        <div className='report-header-head'>
                            <p>Email Address: {email}</p>
                            <p>Date: {today}</p>
                        </div>
                        <div className='report-header'>
                            <div className='report-counter'>
                                <h3><CountUp end={0} duration={1} /></h3>
                                <p>Breaches</p>
                            </div>
                            <div className='report-counter'>
                                <h3><CountUp end={0} duration={1} /></h3>
                                <p>Cleartext Passwords</p>
                            </div>
                            <div className='report-counter'>
                                <h3><CountUp end={0} duration={1} /></h3>
                                <p>Total Unique Details</p>
                            </div>
                        </div>
                    </div>
            
                    <div className='inner-container report-content'>
                        <div className='instances'>
                            <h2>Breach Instance Details</h2>
                            <p className='congratz'>Congrats!  Your email doesn't appear in any of the databases this API has access to.</p>
                        </div>
                    </div>
                </div>
              )
        }
    }


    
}

export const detailsLoader = async ({params}) => {
    const { email } = params

    if(email !== ''){
        if(validateEmail(email)){
            // Email address is valid
            const response = await fetch('https://compromised-report.jdeed.co.uk/lookup/index.php', {
                method: 'POST',
                body: JSON.stringify({
                    email: email
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
    
            if(!response.ok){
                throw Error('Could not fetch data.')
            }
    
            return response.json()
    
        } else {
            // Email address is NOT valid
            const res = '{"balance":0,"entries":[],"success":true,"took":"3µs","total":0}'
    
            return res
    
        }
    } else {
        const res = '{"balance":0,"entries":[],"success":true,"took":"3µs","total":"noemail"}'

        return res
    }

    
}

const getpdf = async (email,resdata,totaldetailsval,totalpasswordval,date) => {
    const response = await fetch('https://compromised-report.jdeed.co.uk/pdf/index.php', {
        method: 'POST',
        body: JSON.stringify({
            email: email,
            totaldetails: totaldetailsval,
            totalpasswords: totalpasswordval,
            date: date,
            data: resdata
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    }).then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "report.pdf";
        link.click();
      }) 
      .catch(error => {
        console.error("An error occurred:", error);
      });

    return response;
}

function countUnique(iterable) {
    return new Set(iterable).size;
}

function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
}