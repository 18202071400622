import './App.css';
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import MainLayout from './layouts/MainLayout';
import Home, {sendEmailAddress} from './pages/Home';
import About from './pages/About';
import Faq from './pages/Faq';
import Reportdetails, {detailsLoader} from './Components/Reportdetails';
import Report from './pages/Report';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />} action={sendEmailAddress}>
      <Route index element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="faq" element={<Faq />} />
      <Route path="report" element={<Report />}/>
      <Route path="report:email" element={<Report />} loader={detailsLoader} />

      <Route path="report">
        <Route index element={<Report />} />
        <Route path=":email" element={<Reportdetails />} loader={detailsLoader}/>
      </Route>

    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />
}

export default App;
