import React, {useState} from 'react'
import Faqitem from './Faqitem'

const Faqlist = () => {
    const [faqs] = useState(
        [
            {
                id:0,
                title: "Where does this information come from?",
                content: "All our information is sourced from the dehashed.com API, they gain their data from hacked databases that have been made public and other public white page data aggregators."
            },
            {
                id:1,
                title: "Can I get my data removed from this API?",
                content: "Yes.  Go to the dehashed.com website, search your email address and click 'REQUEST ENTRY REMOVAL' on the entries you want removed.  Follow the on-screen instructions and your information will be removed.  Please do keep in mind though that this data is sourced from publicly accessable databases so although it is removed from this API, it will still be available in other places."
            },
            {
                id:2,
                title: "How can I stop my details being leaked?",
                content: "The short answer to this is you can't, databases are being leaked all across the internet so if you have an account ANYWHERE it has the chance of being leaked."
            },
            {
                id:3,
                title: "How can I avoid being affected by these leaks?",
                content: "The best way to avoid database leaks impacting you is to use a password manager and using different passwords for every account that you create.  This is so if one accounts details get leaked it will not compromise any other accounts you may have."
            },
            {
                id:4,
                title: "Do you save any data?",
                content: "No, the only data recorded on this website is server/access logs so data such as IP is saved for a short time before being deleted."
            },
            {
                id:5,
                title: "There are passwords that I've never used related to my email address.  Whats with that?",
                content: "Looks like that account had already been comprimised at the time that the database was leaked."
            },
            
        ]
    )
  return (
    <section className='faq' id="faq">
        <h2 className='heading'>FAQ</h2>
        <div className='inner-container'>
             {faqs.map((faq) => (
                <Faqitem key={faq.id} title={faq.title} content={faq.content} />
            ))}
        </div>
       
    </section>
  )
}

export default Faqlist