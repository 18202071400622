import React from 'react'

const About = () => {
  return (
    <div className='inner-container'>
        <div className='about-intro'>
            <h2>About</h2>
            <p>Welcome to our website, a tool for checking if your email address has been exposed in any data breaches. I understand that privacy and data security are top priorities for individuals and businesses alike, and that's why I've created a user-friendly platform to help you stay informed about potential data breaches that may have impacted your email address.</p>
            <p>I know that discovering your email has been exposed in a data breach can be concerning, which is why this platform is designed to help you take action. In addition to providing information about which breaches your email has been exposed in, it also offer tips and resources to help you protect your information and prevent future breaches. I believe that knowledge is power, and by arming users with accurate information, we can work together to protect our digital identities.</p>

            <h2>How do data breaches occur?</h2>
            <p>Data breaches occur when an unauthorized party gains access to sensitive or confidential data, such as personal information, financial records, or intellectual property. There are several ways that data breaches can occur, including:</p>
            <ol>
                <li><p>Hacking: Hackers use various techniques to gain unauthorized access to networks or systems, often exploiting security vulnerabilities or weak passwords.</p></li>
                <li><p>Phishing: Phishing is a social engineering technique that uses fraudulent emails or messages to trick individuals into revealing sensitive information or downloading malware.</p></li>
                <li><p>Malware: Malware is malicious software that can be used to gain unauthorized access to a system or network, often by exploiting vulnerabilities or tricking users into installing it.</p></li>
                <li><p>Physical theft or loss: Physical theft or loss of devices such as laptops, smartphones, or portable storage devices containing sensitive data can also lead to a data breach.</p></li>
                <li><p>Insider threats: Employees or contractors with access to sensitive data can accidentally or intentionally cause a data breach by mishandling data, stealing it, or sharing it with unauthorized parties.</p></li>
                <li><p>Third-party breaches: Data breaches can also occur through third-party vendors, partners, or suppliers who have access to sensitive data and fail to adequately protect it.</p></li>
            </ol>
            <p>Preventing data breaches requires a combination of technical controls, such as firewalls, encryption, and access controls, as well as user awareness and education about best practices for cybersecurity. It's essential for organizations to implement a comprehensive security strategy that addresses these risks and continuously monitor for potential threats.</p>



            <h2>How to limit your exposure to data breaches</h2>
            <ol>
                <li><p>Use unique passwords: A user can reduce their risk of multiple accounts being compromised by using unique passwords for each account. They can also use a password manager to generate and store strong passwords securely.</p></li>
                <li><p>Enable two-factor authentication: Two-factor authentication adds an extra layer of security to an account, requiring the user to provide a code sent to their phone or email in addition to their password to log in.</p></li>
                <li><p>Monitor financial accounts: Regularly checking bank and credit card statements for unauthorized transactions can help the user detect and report any suspicious activity as soon as possible.</p></li>
                <li><p>Be cautious of suspicious emails and messages: Users should be wary of opening emails or messages from unknown senders, clicking on links, or downloading attachments that seem suspicious, and always verify the authenticity of the sender before taking any action.</p></li>
                <li><p>Limit sharing personal information: Users should be cautious about sharing their personal information online, especially on social media, and should only provide it to trusted sources or when it is necessary.</p></li>
                <li><p>Keep software up-to-date: Keeping operating systems, applications, and antivirus software up-to-date can help prevent security vulnerabilities that hackers could exploit.</p></li>
            </ol>
            <p>By taking these steps, users can reduce their risk of being impacted by a data breach and limit the potential harm caused by one. However, it's important to note that even with these measures in place, there is no way to completely eliminate the risk of a data breach, so users should remain vigilant and stay informed about the latest threats and best practices for online safety.</p>
        </div>
    </div>
  )
}

export default About
