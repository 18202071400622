import { Form, redirect, NavLink } from "react-router-dom";

export default function Home() {

  return (
    <div className='App'>
        <section className="hero">
            <div className="inner-container">
                <div className="section">
                    <h1>Have you been compromised?</h1>
                    <p>Enter your email address to get your report...</p>
                    <Form method="post" action="/">
                        <input type="email" name="email" placeholder="example@example.com" />
                        <button type="submit" class="className">Get Report</button>
                    </Form>
                </div>
            </div>
        </section>

        

        <div className="inner-container">
            <section className="intro">
                <div className="section">
                    <h3>Data Breaches</h3>
                    <h2>How does data get compromised?</h2>
                    <p>In 2022 the ID theft center organisation reported that 1,802 website breaches occured with over 422 MILLION accounts comprimised.</p>
                    <NavLink to="about" className="button">Learn More</NavLink>
                </div>
                <div className="section img">
                    <img src="/img/attacks.jpg" />
                </div>
            </section>
        </div>

        <div className="home-fullwidth">
            <div className="inner-container">
                <h2>Why should you care?</h2>
                <p>Data breaches are a growing problem which is only going to get worse, see below at just a few of the reasons data breaches can affect you as an individual.</p>
            </div>
        </div>

        <div className="inner-container">
            <section className="affect">
            <div className="section">
                <i class="fa-solid fa-fingerprint"></i>
                <h2>Identity theft</h2>
                <p>A data breach can expose a user's personal information such as name, address, and social security number, which can be used by cybercriminals to steal the user's identity.</p>
            </div>
            <div className="section">
            <i class="fa-regular fa-credit-card"></i>
                <h2>Financial loss</h2>
                <p>If a user's financial information such as credit card details or bank account information is compromised in a data breach, the user may suffer financial losses due to unauthorized transactions.</p>
            </div>
            <div className="section">
            <i class="fa-regular fa-thumbs-up"></i>
                <h2>Reputation damage</h2>
                <p>A data breach can lead to the public disclosure of sensitive information, which can harm the user's reputation and cause embarrassment or social stigma.</p>
            </div>
            <div className="section">
            <i class="fa-solid fa-shield-halved"></i>
                <h2>Privacy violation</h2>
                <p>A data breach can expose a user's private conversations, messages, or photos, causing emotional distress and damage to personal relationships.</p>
            </div>
            <div className="section">
            <i class="fa-solid fa-lock-open"></i>
                <h2>Further Attacks</h2>
                <p>Hackers can use the user's email or other contact information obtained from a data breach to send spam messages or phishing attacks, which can lead to further data breaches or financial loss.</p>
            </div>
            <div className="section">
                <i className="fa-regular fa-clock"></i>
                <h2>Time and effort</h2>
                <p>Dealing with the aftermath of a data breach can be time-consuming and stressful for the user. The user may have to change passwords, monitor their financial accounts, and take other measures to prevent further harm, which can take a toll on their time and energy.</p>
            </div>
        </section>
        </div>
    </div>
  )

}


export const sendEmailAddress = async ({request}) => {

  const data = await request.formData()

  const submission = {
      email: data.get('email'),
  }

  // Redirect
  return redirect('/report/'+submission.email)

}