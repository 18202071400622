import { NavLink, Outlet } from 'react-router-dom'
import { useState } from 'react';

export default function MainLayout() {
    const [isOpen, setIsOpen] = useState(false);

    

  return (
    <div className='root-layout'>
        <header>
        
            <div className="inner-container">
        
                <div className="brand">
                    <NavLink to="/">Compromised Report</NavLink>
                </div>
        
        
                <a href="javascript:void(0);" className="nav-toggle" onClick={() => setIsOpen(!isOpen)}>
                    <i className="fa fa-bars"></i>
                </a>
        
                <nav  className={`main-nav ${isOpen ? 'active' : ''}`}>
                    <a href="javascript:void(0);" className="nav-toggle-close" onClick={() => setIsOpen(!isOpen)}>
                        <i className="fa fa-xmark"></i>
                    </a>
                    <ul>
                        <li><NavLink to="faq" onClick={() => setIsOpen(false)}>FAQ</NavLink></li>
                        <li><NavLink to="about" onClick={() => setIsOpen(false)}>About</NavLink></li>
                    </ul>
                </nav>
        
            </div>
        
        </header>

        <>
            <Outlet />
        </>

        <footer>
            <p>Copyright &copy; 2023 <a href="https://jdeed.co.uk" target={"_blank"}>jdeed.co.uk</a></p>
        </footer>

    </div>
  )
}