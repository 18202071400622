import { useState } from 'react'

const Faqitem = (props) => {
  const [isOpen, setIsOpen] = useState(false);


  return (
    <div className={`faq-item ${isOpen ? 'active' : ''}`}>
        <div className='faq-heading' onClick={() => setIsOpen(!isOpen)}>
            <p>{props.title}</p>
            <i className="fa-solid fa-caret-down"></i>
        </div>
        <div className='faq-content'>
            <p>{props.content}</p>
        </div>
    </div>
  )
}

export default Faqitem